import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術進行式</h2>
          <h1 className="main_title">戴嘉惠</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_3">
              <figure className="figure">
                <img src={require("../../images/blog/post_3_2.jpg")} alt="戴嘉惠"/>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>15年前初次與公共藝術合作的經驗，當時的藝術家提案形式多元，但獲選團隊在議價階段常遇到以工程導向角度處理作品的安全性與後續維護，或許是這個因素，導致公共藝術提案漸趨保守。</p>
                <p>設計人也希望遇到好的藝術家與提案，甚至願意配合變更設計、延長期程。然而這幾年與公共藝術合作的經驗，被選上的多是較好驗收、維護而非建築師屬意的作品。</p>
                <p>台北市第一期社宅公共藝術計畫中，有兩點與以往公共建築案不同，第一是橫跨興隆一、二區，健康、東明、萬華等五個社宅基地，是目前規模最大的公共藝術案。第二是社宅本身議題，讓這次的公共藝術計畫形式樣貌不同以往。</p>
                <p>第一期計畫內容，若將公共藝術放在一個十字軸中心，向上有文史紀錄的採集，向下有對未來社宅的想像，而左右兩側連結了周遭社區與社宅居民。計畫型內容外，同時設有永久性作品，如公共藝術與兒童遊具結合。</p>
                <p>透過這次計畫，他看到了跟以往不同的公共藝術形式，更進一步提問，建築師與藝術家的交集該何時發生？是建築完工，環境空間、材質、光線等條件已完備，藝術家再進入，讓創作精準回應環境。或是提前藝術家介入時機，讓他們加入前端設計階段，就部分公共空間的建築維護、開發空間、材質等面向進行整合。如金澤21世紀美術館，直接整合美術館空間跟藝術家，這件事只能在前面設計階段，讓藝術家加入設計團隊，才有可能實現。</p>
                <p>最後，建築師提到，審查階段遇到的限制，多來自使用者意見，而非建築師，這個階段，他們也只能在一些細小的部份配合藝術家的創作。他對未來社宅的公共藝術有所期待，遇到好的作品，空間設計、期程等也願意配合，同時也好奇藝術家面對一系列社宅公共藝術，創作時會從什麼角度切入思考，希望將來與藝術家能有更多對話、彼此瞭解的機會。</p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_3_3" className="next_page">
            <div className="next_title">藝術家創作經驗分享</div>
            <div className="next_owner">張耿華</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
